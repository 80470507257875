<template>
  <div>
  
    <div>

        <!-- Field: Username -->
      <b-card>
        <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('ChooseMainSpecialty')"
            label-for="ChooseMainSpecialty"
          >
            <v-select
            label="SpecialtyName"
              v-model="MainSpecialty"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="surgeryCategories"
              :reduce="val => val"
              input-id="ChooseMainSpecialty"
              @input="selectSurgCategory(MainSpecialty,1)"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('ChooseSubSpecialty')"
            label-for="ChooseSubSpecialty"
          >
            <v-select
            label="SpecialtyName"
              v-model="supSpecialty"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="surgeriesByCat"
              :reduce="val => val"
              input-id="ChooseSubSpecialty"
              @input="selectSurgCategory(supSpecialty,2)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      </b-card>


      <b-card>
        <h3 style="font-weight: bold;text-align: center;">
        {{ $t("SurgeryTreatment") }}
        </h3>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :data="hospitalSurgery"
          >

            <template slot="thead">
              <vs-th >{{ $t("Surgery") }}</vs-th>
              <vs-th>{{ $t("Action") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      
                      {{ tr.Surgery.Name}}
                    </p>
                  </vs-td>
                  <vs-td>
                    <b-button
                    variant="primary"
          @click="ViewSurgeryData(tr)">
          {{ $t("GotoTreatment") }}
        </b-button>
                  </vs-td>
                
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </b-card>
    
    </div>
    <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button>
  </div>
</template>

<script>
import HospitalModule from "@/store/hospital/moduleHospital.js";
import moduleSurgery from "@/store/settings/surgery/moduleSurgery.js";
import {
BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow,
BFormGroup
} from "bootstrap-vue";
export default {
  data() {
    return {
      EnglishLan: false,
      surgeryCategories: [],
      surgeriesByCat: [],
      hospitalSpecialty: [],
      ActiveParentIndex: null,
      ActiveChildIndex: null,
      SurgActiveIndex: null,
      hospitalSurgery: [],
      MainSpecialty:{},
      supSpecialty:{},
      SpecialitySelected:[],
      SpecialityUnSelected:[]
    };
  },
  components:{
    BFormGroup,
    BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow
  },
  methods: {
    ViewSurgeryData(surgery) {
      this.$router.push("/TreatmentsProfile/" + surgery.ID + "/" + 1);
    },
    back() {
      this.$router.go(-1);
    },
    selectSurgCategory(data,flag) {
      debugger
      if(flag==1){ //main speciality
        this.surgeriesByCat = this.hospitalSpecialty.filter(
            (ob) => ob.Specialty.ParentSpecialtyID == data.Specialty.ID
          );
      }
      

      debugger;
      if (data != null) {
        

        this.$store
          .dispatch("SurgeryList/SearchHospitalSurgerys", {
            HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
            SpecialtyID: data.Specialty.ID,
          })
          .then((res) => {
            debugger;
            this.hospitalSurgery = res.data.Data;
            debugger;
          });
      } else {
      

        this.surgeriesByCat = this.hospitalSpecialty.filter(
          (ob) => ob.Specialty.ParentSpecialtyID
        );
        this.$store
          .dispatch("SurgeryList/SearchHospitalSurgerys", {
            HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
          })
          .then((res) => {
            this.hospitalSurgery = res.data.Data;
          });
      }
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }

    if (!moduleSurgery.isRegistered) {
      this.$store.registerModule("SurgeryList", moduleSurgery);
      moduleSurgery.isRegistered = true;
    }
    this.$store
      .dispatch("SurgeryList/SearchHospitalSurgerys", {
        HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
      })
      .then((res) => {
        this.hospitalSurgery = res.data.Data;
      });

    this.$store
      .dispatch("HospitalList/SearchHospitalSpecialtys", {
        HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
      })
      .then((res) => {
        this.hospitalSpecialty = res.data.Data;
        this.surgeryCategories = res.data.Data.filter(
          (ob) => !ob.Specialty.ParentSpecialtyID
        );
        this.surgeriesByCat = res.data.Data.filter(
          (ob) => ob.Specialty.ParentSpecialtyID
        );
      });
  },
};
</script>



<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>

