/*=========================================================================================
  File Name: moduleActivityState.js
  Description: Activity Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  surgeries: [],
  surgeriesByCat: [],  
  hospitals: [],
  search:{PageSize : 10,
    PageNumber : 1,IsPaging: true,},
};
