/*=========================================================================================
  File Name: moduleSurgeryActions.js
  Description: Surgery Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
 
import axios from "@/axios.js";


export default {
    AddSurgery({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Surgery/AddSurgery", item)
                .then(response => {
                    commit(
                        "ADD_ITEM",
                        Object.assign(item, { ID: response.data.Data.ID })
                    );
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetAllSurgeries({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Surgery/GetAllSurgeries")
                .then(response => {
                    commit("SET_Surgery", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    SearchSurgeries({ commit },data) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Surgery/SearchSurgeries",data)
                .then(response => {
                    commit("SET_Surgery", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdateSurgery({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Surgery/UpdateSurgery", item)
                .then(response => {
                    commit("UPDATE_Surgery", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetSurgery({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Surgery/GetSurgery", itemid)
                .then(response => {
                    commit("UPDATE_Surgery", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    DeleteSurgery({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .delete("api/Surgery/DeleteSurgery?ID=" + item.ID)
                .then(response => {
                    commit("REMOVE_ITEM", item.ID);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchHospitalSurgerys({ commit }, Model) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/HospitalSurgery/SearchHospitalSurgerys", Model)
                .then(response => {
                    commit("SET_Surgeries", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetSurgerysByCategory({ commit }, Model) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Surgery/GetSurgerysByCategory", {
                    params: {
                        SurgeryCategoryID: Model.SurgeryCategoryID
                    }
                })
                .then(response => {
                    commit("SET_SurgeriesByCat", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchHospitals({ commit }, Model) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/Hospital/SearchHospitals", Model)
                .then(response => {
                    debugger;
                    commit("SET_SearchHospitals", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchHospitalsPaging({ commit }, Model) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("/API/Hospital/SearchHospitals", Model)
                .then(response => {
                    debugger;
                    commit("SET_SearchHospitalsPaging", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAlHospitalSurgeyByCategory({ commit }, obj) {

        return new Promise((resolve, reject) => {
            axios
                .get("/API/Hospital/GetAlHospitalSurgeyByCategory", {
                    params: {
                        SurgeyCategoryID: obj.SurgeyCategoryID,
                        HospitalID: obj.HospitalID

                    }
                })
                .then(response => {
                    debugger;

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};