/*=========================================================================================
  File Name: moduleSurgeryMutations.js
  Description: Surgery Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.surgeries.unshift(item);
  },
  SET_Surgery(state, surgeries) {
    state.surgeries = surgeries;
  },
  SET_Surgeries(state, surgeries) {
    state.surgeries = [];
    state.surgeries = surgeries;
  },SET_SurgeriesByCat(state, surgeriesByCat) {
    state.surgeriesByCat = [];
    state.surgeriesByCat = surgeriesByCat;
  },
  SET_SearchHospitals(state, hospitals) {
    debugger;
    state.hospitals = hospitals;
  },
  SET_SearchHospitalsPaging(state, hospitals){
    hospitals.forEach(element => {
      const ItemIndex = state.hospitals.findIndex(p => p.ID == element.ID);
            if(ItemIndex==-1 )
              state.hospitals.push(element);     
   });
  },
  UPDATE_Surgery(state, Surgery) {
    const SurgeryIndex = state.surgeries.findIndex(p => p.ID == Surgery.ID);
    Object.assign(state.surgeries[SurgeryIndex], Surgery);
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.surgeries.findIndex(p => p.ID == itemId);
    state.surgeries.splice(ItemIndex, 1);
  }
};
